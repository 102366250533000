<template>
  <b-card v-if="elementData">
    <b-card-header>
      <div class="text-primary h2">
        <feather-icon
          icon="StarIcon"
          size="25"
          class="mr-0 mr-sm-50"
          variant="primary" />
        <span class="font-weight-bold"
          >{{ $t(elementName + '.object_name') }}
          {{ elementData.case_number }}</span
        >
      </div>
    </b-card-header>
    <b-card-body>
      <b-row>
        <!-- Right Col: Table -->
        <b-col
          cols="12"
          xl="6">
          <h3 class="mb-1 text-primary text-uppercase">
            <b>{{ $t('case.section.basic_info') }}</b>
          </h3>
          <table class="mt-2 mt-xl-0 w-100">
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="BookOpenIcon"
                  class="mr-75" />
                <span class="font-weight-bold">{{
                  $t(elementName + '.object.case_number')
                }}</span>
              </th>
              <td class="pb-50">
                {{ elementData.case_number }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="BookOpenIcon"
                  class="mr-75" />
                <span class="font-weight-bold">{{
                  $t(elementName + '.object.internal_code')
                }}</span>
              </th>
              <td class="pb-50">
                {{ elementData.internal_code }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="BookOpenIcon"
                  class="mr-75" />
                <span class="font-weight-bold">{{
                  $t(elementName + '.object.case_type')
                }}</span>
              </th>
              <td class="pb-50">
                {{ getByField(elementData, 'case_type') }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="BookOpenIcon"
                  class="mr-75" />
                <span class="font-weight-bold">{{
                  $t(elementName + '.object.arl')
                }}</span>
              </th>
              <td class="pb-50">
                {{ getByField(elementData, 'arl', 'name') }}
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <br />

                <h3 class="mb-1 text-primary text-uppercase">
                  <b>{{ $t('case.section.patient_info') }}</b>
                </h3>
              </td>
            </tr>
            <tr v-if="elementData.patient">
              <th class="pb-50">
                <feather-icon
                  icon="BookOpenIcon"
                  class="mr-75" />
                <span class="font-weight-bold">{{
                  $t(elementName + '.object.patient_document_type')
                }}</span>
              </th>
              <td class="pb-50">
                {{ getByField(elementData.patient, 'document_type') }}
              </td>
            </tr>
            <tr v-if="elementData.patient">
              <th class="pb-50">
                <feather-icon
                  icon="BookOpenIcon"
                  class="mr-75" />
                <span class="font-weight-bold">{{
                  $t(elementName + '.object.patient_identity')
                }}</span>
              </th>
              <td class="pb-50">
                {{ getByField(elementData.patient, 'identity') }}
              </td>
            </tr>
            <tr v-if="elementData.patient">
              <th class="pb-50">
                <feather-icon
                  icon="BookOpenIcon"
                  class="mr-75" />
                <span class="font-weight-bold">{{
                  $t(elementName + '.object.patient_first_name')
                }}</span>
              </th>
              <td class="pb-50">
                {{ getByField(elementData.patient, 'first_name') }}
              </td>
            </tr>
            <tr v-if="elementData.patient">
              <th class="pb-50">
                <feather-icon
                  icon="BookOpenIcon"
                  class="mr-75" />
                <span class="font-weight-bold">{{
                  $t(elementName + '.object.patient_second_name')
                }}</span>
              </th>
              <td class="pb-50">
                {{ getByField(elementData.patient, 'second_name') }}
              </td>
            </tr>
            <tr v-if="elementData.patient">
              <th class="pb-50">
                <feather-icon
                  icon="BookOpenIcon"
                  class="mr-75" />
                <span class="font-weight-bold">{{
                  $t(elementName + '.object.patient_first_surname')
                }}</span>
              </th>
              <td class="pb-50">
                {{ getByField(elementData.patient, 'first_surname') }}
              </td>
            </tr>
            <tr v-if="elementData.patient">
              <th class="pb-50">
                <feather-icon
                  icon="BookOpenIcon"
                  class="mr-75" />
                <span class="font-weight-bold">{{
                  $t(elementName + '.object.patient_second_surname')
                }}</span>
              </th>
              <td class="pb-50">
                {{ getByField(elementData.patient, 'second_surname') }}
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <br />

                <h3 class="mb-1 text-primary text-uppercase">
                  <b>{{ $t('case.section.company_info') }}</b>
                </h3>
              </td>
            </tr>
            <tr v-if="elementData.company">
              <th class="pb-50">
                <feather-icon
                  icon="BookOpenIcon"
                  class="mr-75" />
                <span class="font-weight-bold">{{
                  $t(elementName + '.object.company_nit')
                }}</span>
              </th>
              <td class="pb-50">
                {{ getByField(elementData.company, 'nit') }}
              </td>
            </tr>
            <tr v-if="elementData.company">
              <th class="pb-50">
                <feather-icon
                  icon="BookOpenIcon"
                  class="mr-75" />
                <span class="font-weight-bold">{{
                  $t(elementName + '.object.company_name')
                }}</span>
              </th>
              <td class="pb-50">
                {{ getByField(elementData.company, 'name') }}
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <br />

                <h3 class="mb-1 text-primary text-uppercase">
                  <b>{{ $t('case.section.accident_info') }}</b>
                </h3>
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="BookOpenIcon"
                  class="mr-75" />
                <span class="font-weight-bold">{{
                  $t(elementName + '.object.accident_date')
                }}</span>
              </th>
              <td class="pb-50">
                {{ formatDate(elementData, 'accident_date') }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="BookOpenIcon"
                  class="mr-75" />
                <span class="font-weight-bold">{{
                  $t(elementName + '.object.report_date')
                }}</span>
              </th>
              <td class="pb-50">
                {{ formatDate(elementData, 'report_date') }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="BookOpenIcon"
                  class="mr-75" />
                <span class="font-weight-bold">{{
                  $t(elementName + '.object.accident_place')
                }}</span>
              </th>
              <td class="pb-50">
                {{ getByField(elementData, 'accident_place') }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="BookOpenIcon"
                  class="mr-75" />
                <span class="font-weight-bold">{{
                  $t(elementName + '.object.department')
                }}</span>
              </th>
              <td class="pb-50">
                {{ getByField(elementData, 'department') }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="BookOpenIcon"
                  class="mr-75" />
                <span class="font-weight-bold">{{
                  $t(elementName + '.object.city')
                }}</span>
              </th>
              <td class="pb-50">
                {{ getByField(elementData, 'city') }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="BookOpenIcon"
                  class="mr-75" />
                <span class="font-weight-bold">{{
                  $t(elementName + '.object.known_source')
                }}</span>
              </th>
              <td class="pb-50">
                {{ getByField(elementData, 'known_source') }}
              </td>
            </tr>
            <div
              v-if="
                elementData.known_source &&
                elementData.known_source.key == 'YES'
              ">
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="BookOpenIcon"
                    class="mr-75" />
                  <span class="font-weight-bold">{{
                    $t(elementName + '.object.source_document_type')
                  }}</span>
                </th>
                <td class="pb-50">
                  {{ getByField(elementData, 'source_document_type') }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="BookOpenIcon"
                    class="mr-75" />
                  <span class="font-weight-bold">{{
                    $t(elementName + '.object.source_identity')
                  }}</span>
                </th>
                <td class="pb-50">
                  {{ getByField(elementData, 'source_identity') }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="BookOpenIcon"
                    class="mr-75" />
                  <span class="font-weight-bold">{{
                    $t(elementName + '.object.source_first_name')
                  }}</span>
                </th>
                <td class="pb-50">
                  {{ getByField(elementData, 'source_first_name') }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="BookOpenIcon"
                    class="mr-75" />
                  <span class="font-weight-bold">{{
                    $t(elementName + '.object.source_second_name')
                  }}</span>
                </th>
                <td class="pb-50">
                  {{ getByField(elementData, 'source_second_name') }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="BookOpenIcon"
                    class="mr-75" />
                  <span class="font-weight-bold">{{
                    $t(elementName + '.object.source_first_surname')
                  }}</span>
                </th>
                <td class="pb-50">
                  {{ getByField(elementData, 'source_first_surname') }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="BookOpenIcon"
                    class="mr-75" />
                  <span class="font-weight-bold">{{
                    $t(elementName + '.object.source_second_surname')
                  }}</span>
                </th>
                <td class="pb-50">
                  {{ getByField(elementData, 'source_second_surname') }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="BookOpenIcon"
                    class="mr-75" />
                  <span class="font-weight-bold">{{
                    $t(elementName + '.object.hiv')
                  }}</span>
                </th>
                <td class="pb-50">
                  {{ getBooleanByField(elementData, 'source_positive_hiv') }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="BookOpenIcon"
                    class="mr-75" />
                  <span class="font-weight-bold">{{
                    $t(elementName + '.object.vhc')
                  }}</span>
                </th>
                <td class="pb-50">
                  {{ getBooleanByField(elementData, 'source_positive_vhc') }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="BookOpenIcon"
                    class="mr-75" />
                  <span class="font-weight-bold">{{
                    $t(elementName + '.object.vhb')
                  }}</span>
                </th>
                <td class="pb-50">
                  {{ getBooleanByField(elementData, 'source_positive_vhb') }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="BookOpenIcon"
                    class="mr-75" />
                  <span class="font-weight-bold">{{
                    $t(elementName + '.object.syphilis')
                  }}</span>
                </th>
                <td class="pb-50">
                  {{
                    getBooleanByField(elementData, 'source_positive_syphilis')
                  }}
                </td>
              </tr>
            </div>
            <tr>
              <td colspan="2">
                <br />

                <h3 class="mb-1 text-primary text-uppercase">
                  <b>{{ $t('case.section.authorization_codes') }}</b>
                </h3>
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="BookOpenIcon"
                  class="mr-75" />
                <span class="font-weight-bold">{{
                  $t(elementName + '.object.case_initial_valuation_auth_code')
                }}</span>
              </th>
              <td class="pb-50">
                {{ getByField(elementData, 'initial_valuation_auth_code') }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="BookOpenIcon"
                  class="mr-75" />
                <span class="font-weight-bold">{{
                  $t(elementName + '.object.management_auth_code')
                }}</span>
              </th>
              <td class="pb-50">
                {{ getByField(elementData, 'management_auth_code') }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="BookOpenIcon"
                  class="mr-75" />
                <span class="font-weight-bold">{{
                  $t(elementName + '.object.case_control_auth_code')
                }}</span>
              </th>
              <td class="pb-50">
                {{ getByField(elementData, 'control_auth_code') }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="BookOpenIcon"
                  class="mr-75" />
                <span class="font-weight-bold">{{
                  $t(elementName + '.object.case_laboratories_code')
                }}</span>
              </th>
              <td class="pb-50">
                {{ getByField(elementData, 'laboratories_code') }}
              </td>
            </tr>
            <!-- case resume -->
            <tr>
              <td colspan="2">
                <br />

                <h3 class="mb-1 text-primary text-uppercase">
                  <b>{{ $t('case.object.resumen') }}</b>
                </h3>
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  class="mr-75"
                  icon="BookOpenIcon" />
                <span class="font-weight-bold">{{
                  $t('case.object.case_status')
                }}</span>
              </th>
              <td class="pb-50">
                {{ getByField(elementData, 'case_status') }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  class="mr-75"
                  icon="BookOpenIcon" />
                <span class="font-weight-bold">{{
                  $t('process.object.try_contact')
                }}</span>
              </th>
              <td class="pb-50">
                {{ getProcessStepByField(elementData, 'TRY_CONTACT') }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  class="mr-75"
                  icon="BookOpenIcon" />
                <span class="font-weight-bold">{{
                  $t('actions.result.consent.title')
                }}</span>
              </th>
              <td class="pb-50">
                {{ getProcessStepByField(elementData, 'CONSENT') }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  class="mr-75"
                  icon="BookOpenIcon" />
                <span class="font-weight-bold">{{
                  $t('process.object.add_first_date')
                }}</span>
              </th>
              <td class="pb-50">
                {{ getProcessStepByField(elementData, 'FIRST_DATE') }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  class="mr-75"
                  icon="BookOpenIcon" />
                <span class="font-weight-bold">{{
                  $t('process.object.add_follow_date')
                }}</span>
              </th>
              <td class="pb-50">
                {{ getProcessStepByField(elementData, 'FOLLOW_DATE') }}
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BAvatar,
  BRow,
  BCol,
  BBadge,
} from 'bootstrap-vue'

import { avatarText } from '@core/utils/filter'
import moment from 'moment'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    BCardHeader,
    BCardBody,
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    elementName: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      getByField(element, type, name) {
        if (element && element[type]) {
          if (name) {
            return element[type][name] ? element[type][name] : ''
          }
          return element[type].value ? element[type].value : element[type]
        }
        return ''
      },
      avatarText,
      resolveElementStatusVariant(status) {
        if (status === 0) return 'warning'
        return 'success'
      },
      resolveElementStatusMessage(status) {
        if (status === 0) return this.$t(`${this.elementName}.status.inactive`)
        return this.$t(`${this.elementName}.status.active`)
      },
      getBooleanByField(element, field) {
        if (element[field]) return this.$t('boolean.yes')
        return this.$t('boolean.no')
      },
      formatDate(element, field) {
        return moment(element[field], 'DD/MM/YYYY').format('DD/MM/YYYY')
      },
      getProcessStepByField(element, field) {
        if (element.process?.steps) {
          const step = element.process.steps.find(
            (step) => step.step_type.key === field
          )
          if (step?.step_status?.value) return step.step_status.value
        }
        return ''
      },
    }
  },
}
</script>

<style></style>
