<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0">
      <b-card-body>
        <b-row>
          <!-- Right Col: Table -->
          <b-col
            cols="12"
            xl="6">
            <h3 class="mb-1 text-primary text-uppercase">
              <b>{{ $t('case.section.other_cases') }}</b>
            </h3>
            <table class="mt-2 mt-xl-0 w-100">
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="BookOpenIcon"
                    class="mr-75" />
                  <span class="font-weight-bold">{{
                    $t(
                      RESOURCES_ELEMENT_NAME + '.object.patient_whole_identity'
                    )
                  }}</span>
                </th>
                <td class="pb-50">
                  {{ patient_identity_type }} {{ patient_identity }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="BookOpenIcon"
                    class="mr-75" />
                  <span class="font-weight-bold">{{
                    $t(RESOURCES_ELEMENT_NAME + '.object.patient_name')
                  }}</span>
                </th>
                <td class="pb-50">
                  {{ patient_name }}
                </td>
              </tr>
            </table>
          </b-col>
        </b-row>
        <b-row>
          <!-- Right Col: Table -->
          <b-col
            cols="12"
            xl="12">
            <b-table
              ref="refElementsListTable"
              class="position-relative"
              :items="items"
              responsive
              :fields="tableColumns"
              primary-key="id"
              show-empty
              :empty-text="$t(RESOURCES_ELEMENT_NAME + '.search.not_found')">
              <!-- Column: key -->
              <template #cell(generic_name)="data">
                {{ data.item.generic_name }}
              </template>
              <template #cell(laboratory)="data">
                {{ data.item.laboratory }}
              </template>
              <template #cell(description)="data">
                {{ data.item.description }}
              </template>
              <template #cell(accident_date)="data">
                {{ formatDate(data.item, 'accident_date') }}
              </template>
              <!-- Column: Status -->
              <!-- Column: actions -->
              <template #cell(actions)="data">
                <b-col class="flex flex-row flex-wrap">
                  <b-button
                    id="view-button"
                    style="margin: 1px"
                    variant="primary"
                    :to="{
                      name: 'apps-medical-history-view',
                      params: { case_id: data.item.id, patient_id: patient_id },
                    }">
                    <feather-icon icon="FileTextIcon" />
                  </b-button>
                  <!-- ? DETAILS -->
                  <b-tooltip
                    target="view-button"
                    :title="$t('actions.details')"
                    triggers="hover"
                    :noninteractive="true"
                    fallback-placement="counterclockwise">
                  </b-tooltip>
                </b-col>
              </template>
            </b-table>
            <!-- Pagination -->
            <div class="mx-2 mb-2">
              <b-row>
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end">
                  <b-pagination
                    v-model="currentPage"
                    :per-page="perPage"
                    :total-rows="total"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item">
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18" />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BCardBody,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import router from '@/router'
import ElementStoreModule from '@/views/case/storeModule'
import moment from 'moment'

export default {
  components: {
    BCardBody,
    BTooltip,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  data() {
    return {
      ELEMENT_APP_STORE_MODULE_NAME: '',
      RESOURCES_ELEMENT_NAME: '',
      items: [],
      total: 1,
      perPage: 5,
      currentPage: 1,
      perPageOptions: [5, 10, 25, 50, 100],
      query: '',
      selected_element: {},
      currentSelection: {},
      patient_id: undefined,
      case_id: undefined,
      patient_identity_type: '',
      patient_identity: '',
      patient_name: '',
      tableColumns: [],
    }
  },
  watch: {
    perPage(val) {
      this.fetchElements()
    },
    currentPage(val) {
      this.fetchElements()
    },
    query(val) {
      this.currentPage = 1
      this.fetchElements()
    },
  },
  mounted() {
    this.RESOURCES_ELEMENT_NAME = 'case'
    this.ELEMENT_APP_STORE_MODULE_NAME = `app-ca-mh-l-${this.RESOURCES_ELEMENT_NAME}`
    this.patient_id = router.currentRoute.params.patient_id
    this.case_id = router.currentRoute.params.case_id

    this.tableColumns = [
      {
        key: 'id',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.id`),
        sortable: true,
      },
      {
        key: 'case_number',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.number`),
        sortable: true,
      },
      {
        key: 'case_type_name',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.case_type_name`),
        sortable: true,
      },
      {
        key: 'accident_date',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.accident_date`),
        sortable: true,
      },
      {
        key: 'case_status_name',
        label: this.$t(
          `${this.RESOURCES_ELEMENT_NAME}.object.case_status_name`
        ),
        sortable: true,
      },
      {
        key: 'actions',
        label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.actions`),
        thStyle: 'width: 300px;',
      },
    ]
    // Register module
    if (!store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME))
      store.registerModule(
        this.ELEMENT_APP_STORE_MODULE_NAME,
        ElementStoreModule
      )

    this.fetchElements()
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME))
      store.unregisterModule(this.ELEMENT_APP_STORE_MODULE_NAME)
  },
  methods: {
    formatDate(element, field) {
      return moment(element[field], 'DD/MM/YYYY').format('DD/MM/YYYY')
    },
    fetchElements() {
      console.info(this.currentPage)

      store
        .dispatch(
          `${this.ELEMENT_APP_STORE_MODULE_NAME}/fetch_other_elements`,
          {
            queryParams: {
              perPage: this.perPage,
              page: this.currentPage,
            },
            patient_id: this.patient_id,
            case_id: this.case_id,
          }
        )
        .then((response) => {
          this.items = response.data.items
          this.total = response.data.total
          if (this.items && this.items.length > 0) {
            this.patient_identity_type = this.items[0].patient_identity_type
            this.patient_identity = this.items[0].patient_identity
            this.patient_name = this.items[0].patient_name
          }
        })
        .catch(() => {})
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
