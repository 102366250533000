<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            cols="12"
            md="3"
          >
            <label>{{ $t('table.search.show') }}</label>
            <v-select
              v-model="perPage"
              :clearable="false"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('table.search.entries') }}</label>
          </b-col>
          <b-col
            class="d-flex justify-content-start mb-1 mb-md-0"
            cols="12"
            md="9"
          >
            <b-form-input
              v-model="query"
              :placeholder="$t('table.search.search')"
              class="d-inline-block mr-1"
            />
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refElementsListTable"
        class="position-relative"
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        :empty-text="$t(RESOURCES_ELEMENT_NAME + '.search.not_found')"
      >
        <!-- column: name -->
        <template #cell(name)="data">
          <b-badge
            pill
            class="text-capitalize"
          >
            {{ data.item.name }}
          </b-badge>
        </template>
        <!-- Column: key -->
        <template #cell(registry_time)="data">
          {{ showDateTime(data.item.registry_time) }}
        </template>
        <!-- Column: Status -->
        <!-- Column: actions -->

      </b-table>
      <!-- Pagination -->
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="total"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Filters from '@/views/logbook/list/filters.vue'
import store from '@/store'
import router from '@/router'
import ElementStoreModule from '@/views/logbook/storeModule'
import * as XLSX from 'xlsx'
import moment from 'moment'

export default {

  components: {
    Filters,
    BTooltip,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  data() {
    return {
      ELEMENT_APP_STORE_MODULE_NAME: '',
      RESOURCES_ELEMENT_NAME: '',
      items: [],
      total: 1,
      perPage: 5,
      currentPage: 1,
      perPageOptions: [5, 10, 25, 50, 100],
      query: '',
      selected_element: {},
      currentSelection: {},
      case_id: undefined,
      tableColumns: [
      ],
    }
  },
  watch: {
    perPage(val) {
      this.fetchElements()
    },
    currentPage(val) {
      this.fetchElements()
    },
    query(val) {
      this.currentPage = 1
      this.fetchElements()
    },
  },
  mounted() {
    this.RESOURCES_ELEMENT_NAME = 'logbook'
    this.ELEMENT_APP_STORE_MODULE_NAME = `app-log-li-${this.RESOURCES_ELEMENT_NAME}`
    this.tableColumns = [
      { key: 'user_value', label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.user`), sortable: true },
      { key: 'management_medium_value', label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.management_medium`), sortable: true },
      { key: 'reason_value', label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.reason`), sortable: true },
      { key: 'result_value', label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.result`), sortable: true },
      { key: 'registry_time', label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.registry_time`), sortable: true },
    ]
    // Register module
    if (!store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.registerModule(this.ELEMENT_APP_STORE_MODULE_NAME, ElementStoreModule)
    this.case_id = router.currentRoute.params.case_id
    this.fetchElements()
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.unregisterModule(this.ELEMENT_APP_STORE_MODULE_NAME)
  },
  methods: {
    showDateTime(date) {
      return moment(date).format('DD/MM/YYYY HH:mm')
    },
    filterJson(jsonData) {
      const filterList = this.tableColumns.map(a => a.key)
      filterList.pop()
      return jsonData.map(v => filterList.map(j => v[j]))
    },
    getHeader() {
      const res = {}
      for (let i = 0; i < this.tableColumns.length; i++) {
        res[this.tableColumns[i].key] = this.tableColumns[i].label
      }
      return res
    },
    format(jsonData) {
    },
    async export2excel() {
      const filename = this.$t(`${this.RESOURCES_ELEMENT_NAME}.object_name`)
      const result = await store.dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetch_elements`, {
        queryParams: {
          perPage: 10000,
          page: 1,
          query: this.query,
        },
      })
      this.format(result.data.items)
      result.data.items.unshift(this.getHeader())
      const list = this.filterJson(result.data.items)
      const data = XLSX.utils.json_to_sheet(list, { skipHeader: true })
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, data, filename)
      XLSX.writeFile(workbook, `${filename}.xlsx`)
    },
    fetchElements() {
      store.dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetch_logbooks`, {
        queryParams: {
          perPage: this.perPage,
          page: this.currentPage,
          query: this.query,
        },
        case_id: this.case_id,
      })
        .then(response => {
          this.items = response.data.items
          this.total = response.data.total
        })
        .catch(() => {

        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
