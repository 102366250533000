<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="elementData === undefined"
    >
      <h4 class="alert-heading">
        {{ $t(ELEMENT_NAME + '.search.error.fetching_data') }}
      </h4>
    </b-alert>
    <template v-if="elementData">
      <b-card>
        <b-card-header>
          <div class="text-primary h2">
            <feather-icon
              icon="StarIcon"
              size="25"
              class="mr-0 mr-sm-50"
              variant="primary"
            />
            <span class="d-none d-sm-inline">{{ $t('patient.section.digital_file') }} {{ digitalFileName }}</span>
          </div>
        </b-card-header>
        <b-card-body>

          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <tree-item
                :item="elementData"
              />
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BButton, BAlert, BLink,
} from 'bootstrap-vue'
import ElementInfoCard from '@/views/case/received/view/InfoCard.vue'
import TreeItem from '@/views/core/TreeView.vue'
import { data } from 'vue-echarts'
import ElementStoreModule from '../storeModule'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    TreeItem,
    BRow,
    BCol,
    BButton,
    BAlert,
    BLink,
    // Local Components
    ElementInfoCard,
  },
  data() {
    return {
      digitalFileName: '',
      elementData: {},
      patient_name: '',
      case_name: '',
      ELEMENT_APP_STORE_MODULE_NAME: '',
      ELEMENT_NAME: '',
    }
  },
  async mounted() {
    this.ELEMENT_NAME = 'digital_file'
    this.ELEMENT_APP_STORE_MODULE_NAME = `app-df-v-${this.ELEMENT_NAME}`
    // Register module
    if (!store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.registerModule(this.ELEMENT_APP_STORE_MODULE_NAME, ElementStoreModule)
    await this.fetchPatient()
    this.fetchElement()
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.unregisterModule(this.ELEMENT_APP_STORE_MODULE_NAME)
  },
  methods: {
    getPersonName(element) {
      return `${element.first_name} ${
        element.second_name ? `${element.second_name} ` : ''
      }${element.first_surname} ${
        element.second_surname ? `${element.second_surname} ` : ''}`
    },
    process_data(root, is_folder) {
      const ret = {
        path: root.path,
        name: root.name,
        is_folder,
        children: [],
      }
      if (!is_folder && root.documental_type && root.documental_type.name) {
        let extension = root.name.split('.')
        extension = extension.length > 0 ? extension[extension.length - 1] : ''
        ret.name = `${root.documental_type.name} - ${root.name}`
        ret.download_name = `${root.documental_type.name}_${this.patient_name}_${this.case_name}.${extension}`
      }
      if (root.folders) {
        for (const data of root.folders) {
          ret.children.push(this.process_data(data, true))
        }
      }
      if (root.files) {
        for (const data of root.files) {
          ret.children.push(this.process_data(data, false))
        }
      }
      return ret
    },
    async fetchPatient() {
      const _this = this
      return store.dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetch_element`, { id: router.currentRoute.params.patient_id })
        .then(response => {
          _this.elementData = response.data
          _this.patient_name = response.data.identity
          _this.digitalFileName = `${_this.getPersonName(response.data)} ${response.data.document_type.value} ${response.data.identity}`
          return true
        })
        .catch(error => {
          if (error.response.status === 404) {
            _this.digitalFileName = ''
          }
          return false
        })
    },
    fetchElement() {
      store.dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetch_digital_file_case`, { id: router.currentRoute.params.case_id })
        .then(response => {
          const data = response.data.folders[0]
          this.case_name = data.name
          data.name = `CASO N° ${data.name}`
          this.elementData = this.process_data(data, true)
          console.info(this.elementData)
        })
        .catch(error => {
          if (error.response.status === 404) {
            console.info('404', this.elementData)
            this.elementData = undefined
          }
        })
    },
  },
}
</script>

<style>

</style>
